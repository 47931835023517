import { IEnvironment } from "../interfaces/environment.i";

export const staging: IEnvironment = {
  production: false,
  staging: true,
  internal: {
    mailto: 'info@spcgroup.co.id',
    wato: '62816708290',
    faWato: '+62 816 708 290',
    baMandiri: 1270010258919,
    faMandiri: '127 0010 2589 19',
    bnMandiri: 'SINERGI TEKNO CIPTA',
  }
};
