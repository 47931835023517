exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-career-tsx": () => import("./../../../src/pages/career.tsx" /* webpackChunkName: "component---src-pages-career-tsx" */),
  "component---src-pages-client-tsx": () => import("./../../../src/pages/client.tsx" /* webpackChunkName: "component---src-pages-client-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-detail-tsx": () => import("./../../../src/pages/news/detail.tsx" /* webpackChunkName: "component---src-pages-news-detail-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-services-business-process-tsx": () => import("./../../../src/pages/services/business-process.tsx" /* webpackChunkName: "component---src-pages-services-business-process-tsx" */),
  "component---src-pages-services-employee-relation-and-compliance-tsx": () => import("./../../../src/pages/services/employee-relation-and-compliance.tsx" /* webpackChunkName: "component---src-pages-services-employee-relation-and-compliance-tsx" */),
  "component---src-pages-services-headhunter-and-recruitment-tsx": () => import("./../../../src/pages/services/headhunter-and-recruitment.tsx" /* webpackChunkName: "component---src-pages-services-headhunter-and-recruitment-tsx" */),
  "component---src-pages-services-payroll-tsx": () => import("./../../../src/pages/services/payroll.tsx" /* webpackChunkName: "component---src-pages-services-payroll-tsx" */),
  "component---src-pages-services-staffing-tsx": () => import("./../../../src/pages/services/staffing.tsx" /* webpackChunkName: "component---src-pages-services-staffing-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */)
}

